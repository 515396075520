import React from "react";
import { useIntl, Link } from "@intractive/gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogArticleList = props => {
  const { articles, pageNumber } = props;
  const intl = useIntl();
  return (
    <ul className="blog_articles">
      {articles.edges && articles.edges.map(function({ node }, index){
        const article = node;
        const url = article.migrationId ? `/actueel/${article.migrationId}/${article.slug}/` : `/actueel/${article.slug}/`
        return (
          <li className="blog_article" key={index} id={index}>
            <article className="blog-listing-article" itemScope="" itemType="http://schema.org/Article">
              <div className="titeldatum">
                <h2 className="blog-listing-title">
                  <Link to={url} state={{ parentUrl: pageNumber }}><span itemProp="name">{article.title}</span></Link>
                </h2>
                <div className="blog-listing-date">
                  <time itemProp="dateCreated" dateTime={article.date}>{article.date}</time>
                </div>
              </div>
              {article.image &&
                <div className="imagecontainer">
                  <Link to={url} state={{ parentUrl: pageNumber }}>
                    <GatsbyImage
                      image={article.image.gatsbyImageData}
                      alt={article.image.title}
                      className="blog-image" />
                  </Link>
                </div>
              }
              {article.lead &&
                <div
                  className="blog-listing-lead"
                  dangerouslySetInnerHTML={{
                    __html: article.lead.childMarkdownRemark.html,
                  }}
                />
              }
              <div className="blog-listing-more">
                <Link itemProp="url" to={url} title={article.title} className="blog-listing-more-link circle button-next">{intl.formatMessage({id: "Lees verder!"})}</Link>
              </div>
            </article>
          </li>
        );
      })}
    </ul>
  );
}

export default BlogArticleList
