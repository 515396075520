import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { useIntl, Link } from "@intractive/gatsby-plugin-react-intl";
import BlogArticleList from "../BlogArticleList"
import Layout from '../Layout'

const BlogArchive = props => {
  const { pageContext, data } = props;
  const { humanPageNumber, numberOfPages, nextPagePath, previousPagePath } = pageContext;
  const intl = useIntl();

  return (
  <Layout>
    <Helmet>
      <title>{intl.formatMessage({id:"Actueel"})} · STIP</title>
    </Helmet>
    <section role="main" className="cf">
      <div className="main-content">

        <div className="row blog-listing">
          <div className="large-12 columns">
            {data.articles &&
              <BlogArticleList articles={data.articles} pageNumber={humanPageNumber} />
            }
          </div>
        </div>


        <div className="row cf">
          <div className="large-12 columns pagination">
              <div className="blog-pagination">
               <Link to={previousPagePath}>← {intl.formatMessage({id: "vorige"})}</Link>  {intl.formatMessage({id: "Pagina"})} {humanPageNumber} {intl.formatMessage({id: "van"})} {numberOfPages}  <Link to={nextPagePath}>{intl.formatMessage({id: "volgende"})} →</Link>
              </div>
          </div>
        </div>

      </div>
    </section>
  </Layout>
  )
}

export default BlogArchive

export const query = graphql`
  query($skip: Int!, $limit: Int!, $node_locale: String) {
    articles: allContentfulBlogartikel(
      sort: {  fields: date order: DESC }
      skip: $skip
      limit: $limit
      filter: {node_locale: {eq: $node_locale}}
    ) {
      edges {
        node {
          id
          migrationId
          slug
          date(formatString: "DD - MM - YYYY")
          title
          lead {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            gatsbyImageData(formats: WEBP, width: 550)
          }
        }
      }
    }
  }
`
